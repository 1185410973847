<template>
    <div v-loading="loading" class="p-5 bg-white rounded-lg min-h-[500px]">
        <h1 class="my-5 text-xl text-center">聊天室系統設定</h1>
        <div class="grid grid-cols-2 gap-4">
            <div class="flex items-center px-5">
                <h2 class="flex-1 text-base font-medium md:text-xl">
                    開關聊天室客服訊息發送至 Telegram
                </h2>
                <el-switch
                    v-model="form.open_service_room_send_telegram"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="true"
                    :inactive-value="false"
                    @change="updateSettings"
                >
                </el-switch>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
import { firebaseConnectRef } from "@/plugins/firebase";
import firebase from "firebase/app";
// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const loading = ref(false);
  const form = ref({
    open_service_room_send_telegram: false
  });
  async function getSettings() {
    try {
      const data = await firebaseConnectRef("system_settings").get();
      if (!data.exists()) {
        form.value.open_service_room_send_telegram = false;
        return;
      }
      form.value.open_service_room_send_telegram = data.val().open_service_room_send_telegram;
      return;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得是否發送訊息至 telegram 設定值失敗"
      });
    }
  }
  // 更新註冊發送訊息資料
  async function updateSettings() {
    try {
      await firebaseConnectRef("system_settings").set({
        ...form.value,
        updatedAt: firebase.database.ServerValue.TIMESTAMP
      });
      proxy.$message({
        type: "success",
        message: "更新成功"
      });
    } catch (err) {
      console.log(err);
      form.value.open_service_room_send_telegram = !form.value.open_service_room_send_telegram;
      proxy.$message({
        type: "error",
        message: "更新失敗"
      });
    }
  }
  getSettings();
  return {
    loading,
    form,
    updateSettings
  };
};
export default __sfc_main;
</script>
